import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  content: {
    padding: 0,
  },
  alert: {
    padding: theme.spacing(1),
  },
  slider: {
    width: 280,
  },
  cropWrapper: {
    overflowY: "auto",
    maxHeight: "60vh",
    display: "flex",
    justifyContent: "center",
    height: "100%",
  },
  centered: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
    position: "relative",
  },
  enhancingImage: {
    position: "absolute",
    top: "calc(50% - 75px)",
    left: "calc(50% - 75px)",
  },
  reactCrop: {
    maxWidth: "100%",
    "& .ReactCrop__child-wrapper": {
      height: "100%",
      maxWidth: "100%",
      display: "flex",
      justifyContent: "center",
    },
  },
}));
