import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";

import Grid from "@mui/material/Grid";
import { FormCategory } from "@simplyk/common";
import { PixelCrop } from "react-image-crop";

import { isTest } from "../../constants/env";
import { MAX_IMAGE_SIZES } from "../../constants/image";
import { useTranslate } from "../../hooks/useTranslate";
import { ImageType } from "../ImageUploader/type";
import { getSizeInBite } from "../ImageUploader/useImageUploader";

import { Crop, CroppedFile } from "./Crop";
import { canvasPreview } from "./cropPreview";
import { useCloudinary } from "./useCloudinary";
import { useStyles } from "./useStyles";

import { AmplitudeEvents } from "@/constants/amplitude";
import { Button } from "@/design-system/Button";
import { Dialog } from "@/design-system/Dialog";
import { Infobox } from "@/design-system/Infobox";
import { useAmplitude } from "@/hooks/amplitude/useAmplitude";
import { ArrowForward } from "@/icons/outlined";
import { ImageDimensions } from "@/types/image";

const LIMIT_10_MB = 10_000_000; // 10 MB

const isTooSmall = (dimensions: ImageDimensions, maxDimensions: ImageDimensions | undefined, isCompressed: boolean) => {
  if (isTest) {
    //cypress fixture updload image that are always too small: upscaling should not be triggered in test mode
    return false;
  }
  if (
    maxDimensions &&
    [MAX_IMAGE_SIZES.logo, MAX_IMAGE_SIZES.shopRateItem, MAX_IMAGE_SIZES.signature].includes(maxDimensions)
  ) {
    //do not upscale other image type because their max size is under the small limit: so will always be enhanced
    return false;
  }
  //If image is compressed (size bigger thant maxSize) the pixel ratio should be taken as 1 to check the dimensions
  const pixelRatio = isCompressed ? 1 : window.devicePixelRatio;
  //we enhance everything under 800 in height, that give 440 in height with 18/9 aspect ratio
  return dimensions.width / pixelRatio < 800 || dimensions.height / pixelRatio < 440;
};

interface CropDialogProps {
  onCrop: (image: string) => void;
  onClose: () => void;
  croppedFile?: CroppedFile;
  isOpen: boolean;
  ratio: number;
  maxDimensions: ImageDimensions | undefined;
  isEditing?: boolean;
  formType?: FormCategory;
  imageType: ImageType;
  actualImageMetaData:
    | {
        width: number;
        height: number;
        size: number;
        extension: string;
      }
    | undefined;
  fillImageMetadata: (url: string) => void;
  onFileChange: (file: File) => Promise<void>;
  handlePickImage: () => void;
  setCroppedFile: Dispatch<SetStateAction<CroppedFile | undefined>>;
  amplitudePayload?: Record<string, unknown>;
}

enum UploadInfo {
  ImageSizeLimitOverflow = "ImageSizeLimitOverflow",
  ImageNotFount = "ImageNotFount",
  ImageTooSmall = "ImageTooSmall",
  ImageUpScaled = "ImageUpScaled",
}

export const CropDialog = ({
  onCrop,
  onClose,
  croppedFile,
  isOpen,
  ratio = 16 / 9,
  maxDimensions,
  isEditing,
  formType,
  imageType,
  actualImageMetaData,
  fillImageMetadata,
  onFileChange,
  handlePickImage,
  setCroppedFile,
  amplitudePayload,
}: CropDialogProps) => {
  const classes = useStyles();
  const { t } = useTranslate();

  const { logAmplitudeEvent } = useAmplitude();
  const { getUpScaledImage } = useCloudinary();

  const [info, setInfo] = useState<
    { value: UploadInfo; severity: "warning" | "brand" | "danger" | "positive" } | undefined
  >(undefined);
  const [image, setImage] = useState<string | undefined>(undefined);
  const [rotate, setRotate] = useState(0);
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>(); // control the canvas
  const [imageIsEnhancing, setImageIsEnhancing] = useState(false);
  const imageUpscaled = useRef(false); //follow if image was upscaled
  const [imageCroppedByUser, setImageCroppedByUser] = useState(false); //follow if image was cropped by user
  const blockEnhancement = useRef(false); //block enhancement if image is already enhanced or large enough
  const imageDownscaled = useRef(false);
  const [animationMinimumDelay, setAnimationMinimumDelay] = useState(false);
  const imgRef = useRef<HTMLImageElement>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);

  const handleAmplitudeEvent = useCallback(
    async (event: AmplitudeEvents) => {
      logAmplitudeEvent(event, {
        is_new_form: !isEditing,
        form_type: formType,
        ...(event === AmplitudeEvents.FormImageUploadSubmitted && {
          new_image_cropped: imageCroppedByUser,
          new_image_size_modified: imageUpscaled.current
            ? "Upscaled"
            : imageDownscaled.current
              ? "Downscaled"
              : "No modification",
          ...amplitudePayload,
        }),
        // disable this props until fixed
        original_image_size: actualImageMetaData?.size,
        original_image_width: actualImageMetaData?.width,
        original_image_height: actualImageMetaData?.height,
        original_image_type: imageType,
        original_image_extension: actualImageMetaData?.extension,
      });
    },
    [
      actualImageMetaData?.extension,
      actualImageMetaData?.height,
      actualImageMetaData?.size,
      actualImageMetaData?.width,
      amplitudePayload,
      formType,
      imageCroppedByUser,
      imageType,
      isEditing,
      logAmplitudeEvent,
    ]
  );

  const handleAnimationMinimumDelay = useCallback(() => {
    setAnimationMinimumDelay(true);
    setTimeout(() => {
      setAnimationMinimumDelay(false);
    }, 3000);
  }, []);

  const [revertCroppedFile, setRevertCroppedFile] = useState<CroppedFile | undefined>(undefined);
  const [revertImage, setRevertImage] = useState<string | undefined>(undefined);
  const [revertCompletedCrop, setRevertCompletedCrop] = useState<PixelCrop>(); // control the canvas

  const handleUpscaleImage = useCallback(
    async (imageToEnhance: { image: string; dimensions: ImageDimensions }) => {
      //Use to avoid multiple enhancement: only enhanced once at first loading
      if (blockEnhancement.current) {
        return;
      }
      setImageIsEnhancing(true);
      setRevertCroppedFile({ url: croppedFile?.url as string, type: croppedFile?.type as string });
      setRevertCompletedCrop(completedCrop);
      setRevertImage(imageToEnhance.image);
      blockEnhancement.current = true;
      imageUpscaled.current = true;
      handleAnimationMinimumDelay();
      //Get the upscaled image as a Blob
      const upScaledImage = await getUpScaledImage(imageToEnhance.image);
      if (!upScaledImage) {
        setInfo({ value: UploadInfo.ImageNotFount, severity: "danger" });
        return;
      }
      // convert the blob to a file image
      const reader = new FileReader();
      reader.readAsDataURL(upScaledImage);
      reader.onloadend = async () => {
        if (reader.result instanceof ArrayBuffer) {
          return;
        }
        fillImageMetadata(reader.result as string);
        setImage(reader.result as string);
        setInfo({ value: UploadInfo.ImageUpScaled, severity: "positive" });
        onFileChange(upScaledImage as File);
        setImageIsEnhancing(false);
        //Need to reset the completed crop to force user to use it after upscale
        setCompletedCrop(undefined);
      };
    },
    [
      completedCrop,
      croppedFile?.type,
      croppedFile?.url,
      getUpScaledImage,
      handleAnimationMinimumDelay,
      fillImageMetadata,
      onFileChange,
    ]
  );

  const handleRevertEnhancement = useCallback(() => {
    if (revertCroppedFile && revertImage) {
      imageUpscaled.current = false;
      setCroppedFile(revertCroppedFile);
      setCompletedCrop(revertCompletedCrop);
      fillImageMetadata(revertImage);
      setImage(revertImage);
      setRevertCroppedFile(undefined);
      setInfo(undefined);
    }
  }, [fillImageMetadata, revertCompletedCrop, revertCroppedFile, revertImage, setCroppedFile]);

  const handleChange = useCallback(
    async (
      src: string,
      dimensions: ImageDimensions,
      isCompressed: boolean,
      imageToEnhance: {
        image: string;
        dimensions: ImageDimensions;
      }
    ) => {
      if (!src) {
        setInfo({ value: UploadInfo.ImageNotFount, severity: "danger" });
      }
      if (getSizeInBite(src) > LIMIT_10_MB) {
        setInfo({ value: UploadInfo.ImageSizeLimitOverflow, severity: "danger" });
        //blockEnhancement is used to avoid multiple enhancement: only enhanced once at first loading
      } else if (isTooSmall(dimensions, maxDimensions, isCompressed) && !blockEnhancement.current) {
        await handleUpscaleImage(imageToEnhance);
        return;
      } else {
        setImage(src);
      }
      //We always consider image upscaled to avoid multiple upscale, specially when image is large enough on first load
      blockEnhancement.current = true;
      fillImageMetadata(src);
    },
    [fillImageMetadata, handleUpscaleImage, maxDimensions]
  );

  const validateCrop = useCallback(async () => {
    //we block validation if enhancement still running because it can override the enhanced image
    if (!completedCrop || imageIsEnhancing) {
      return;
    }
    // when width and height are too small, the data url is empty: "data:,"
    if (completedCrop.width > 1 && completedCrop.height > 1 && imgRef?.current && previewCanvasRef?.current) {
      //image is the one displayed in the cropper and imageToEnhance is the one that will be enhanced if needed
      const { image, height, width, isCompressed, imageToEnhance } = await canvasPreview(
        croppedFile?.type as string,
        imgRef.current,
        previewCanvasRef.current,
        completedCrop,
        maxDimensions,
        rotate
      );
      imageDownscaled.current = isCompressed;
      await handleChange(image, { width, height }, isCompressed, imageToEnhance);
      return image;
    }
  }, [completedCrop, imageIsEnhancing, croppedFile?.type, maxDimensions, rotate, handleChange]);

  useEffect(() => {
    validateCrop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedCrop]);

  const handleRotate = useCallback(() => {
    handleAmplitudeEvent(AmplitudeEvents.FormImageRotated);
    setRotate((rotate) => rotate + 90);
  }, [handleAmplitudeEvent]);

  const handleClose = useCallback(() => {
    handleAmplitudeEvent(AmplitudeEvents.FormImageUploadCancelled);
    onClose();
  }, [handleAmplitudeEvent, onClose]);

  const handleCrop = useCallback(async () => {
    handleAmplitudeEvent(AmplitudeEvents.FormImageUploadSubmitted);
    //We revalidate to be sure to never overcome the size limit
    const image = await validateCrop();
    if (image) {
      onCrop(image);
    }
  }, [handleAmplitudeEvent, onCrop, validateCrop]);

  const handleReplace = useCallback(() => {
    blockEnhancement.current = false;
    imageUpscaled.current = false;
    handlePickImage();
    setRevertImage(undefined);
    setRevertCroppedFile(undefined);
    setInfo(undefined);
  }, [handlePickImage]);

  return (
    <Dialog
      title={t("dashboard", "cropDialog.title")}
      open={isOpen}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      disableEnforceFocus
      classes={{ content: classes.content }}
      actions={{
        main: {
          children: t("dashboard", "common.save"),
          onClick: handleCrop,
          disabled: info?.severity === "danger" || !image || imageIsEnhancing || !completedCrop,
          ["data-test"]: "crop-validate",
        },
        additional: [
          {
            children: t("dashboard", "common.revert"),
            onClick: handleRevertEnhancement,
            vibe: "neutral",
            variant: "outlined",
            hidden: !revertCroppedFile || imageIsEnhancing,
            disabled: !revertCroppedFile || imageIsEnhancing,
          },
          {
            children: t("dashboard", "common.replace"),
            onClick: handleReplace,
            vibe: "neutral",
            variant: "outlined",
            disabled: imageIsEnhancing,
          },
        ],
      }}
      hideIcon
    >
      <Grid container>
        {info && (
          <Grid item xs={12} className={classes.alert}>
            <Infobox vibe={info.severity}>
              {info.value === UploadInfo.ImageNotFount
                ? t("dashboard", "cropDialog.imageNotFound")
                : info.value === UploadInfo.ImageSizeLimitOverflow
                  ? t("dashboard", "cropDialog.imageSizeLimitOverflow")
                  : info.value === UploadInfo.ImageTooSmall
                    ? t("dashboard", "cropDialog.imageTooSmall")
                    : info.value === UploadInfo.ImageUpScaled
                      ? t("dashboard", "cropDialog.imageUpScaled")
                      : t("dashboard", "cropDialog.unknownError")}
            </Infobox>
          </Grid>
        )}
        <Grid item xs={12} className={classes.cropWrapper}>
          <div>
            <Crop
              key={croppedFile?.url ? croppedFile?.url : "empty"}
              setImageCroppedByUser={setImageCroppedByUser}
              croppedFile={croppedFile}
              ratio={ratio}
              rotate={rotate}
              handleAmplitudeEvent={handleAmplitudeEvent}
              setCompletedCrop={setCompletedCrop}
              completedCrop={completedCrop}
              imgRef={imgRef}
              previewCanvasRef={previewCanvasRef}
              animationMinimumDelay={animationMinimumDelay}
              imageIsEnhancing={imageIsEnhancing}
            />
          </div>
        </Grid>
        <Grid item xs={12} className={classes.alert}>
          <Grid container justifyContent="center" alignItems="center" spacing={2}>
            <Grid item>
              <Button variant="text" size="medium" vibe="brand" onClick={handleRotate} startIcon={<ArrowForward />}>
                {t("common", "rotate")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};
