import { useCallback, useState, ChangeEvent, UIEvent } from "react";

import Grid from "@mui/material/Grid";
import { SelectChangeEvent } from "@mui/material/Select";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";

import { UnsplashOrderBy, UnsplashOrientation } from "../../gql/gql-types";
import {
  SearchUnsplashImagesQuery,
  useSearchUnsplashImagesQuery,
  useTrackDownloadMutation,
} from "../../gql/queries/generated/unsplashQuery";
import { useTranslate } from "../../hooks/useTranslate";
import { Chip } from "../design-system/Chip";
import { Dialog } from "../design-system/Dialog";
import { Icon } from "../design-system/Icon";
import { MenuItem } from "../design-system/MenuItem";
import { Select } from "../design-system/Select";
import { TextField } from "../design-system/TextField";
import Image from "../Image/Image";
import { useDebounce } from "../TextField/useDebounce";

import { useStyles } from "./useStyles";

import { Search } from "@/icons/outlined";

interface SelectImageDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSelectImage: (image: string) => void;
}

interface UnsplashImage {
  id: string;
  width: number;
  height: number;

  alt_description?: string | null;
  urls: {
    small: string;
    regular: string;
    full: string;
  };
  user: {
    id: string;
    name: string;
  };
  links?: {
    download_location?: string | null;
  };
}

export const SelectImageDialog = ({ isOpen, onClose, onSelectImage }: SelectImageDialogProps) => {
  const [execTrackDownload] = useTrackDownloadMutation();
  const { t } = useTranslate();
  const classes = useStyles();
  const [search, setSearch] = useState<string | null>(null);
  const [query, setQuery] = useState<string | null>(null);
  const [displayKeyWords, setDisplayKeyWords] = useState<boolean>(true);

  const [orientation, setOrientation] = useState<UnsplashOrientation | "all">("all");
  const handleChangeOrientation = useCallback((e: SelectChangeEvent<unknown>) => {
    setOrientation(e.target.value as UnsplashOrientation);
  }, []);

  const [orderBy, setOrderBy] = useState<UnsplashOrderBy>(UnsplashOrderBy.latest);
  const handleChangeOrder = useCallback((e: SelectChangeEvent<unknown>) => {
    setOrderBy(e.target.value as UnsplashOrderBy);
  }, []);

  const { setDebouncedValue } = useDebounce({
    onChange: (value: string) => setQuery(value),
    debounced: 300,
  });
  const handleChangeSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setDebouncedValue(event.target.value);
      setSearch(event.target.value);
    },
    [setDebouncedValue]
  );

  const selectUnsplashImage = useCallback(
    (image: UnsplashImage) => () => {
      if (image.links?.download_location) {
        // Allows Unsplash to track downloads
        execTrackDownload({ variables: { location: image.links.download_location } });
      }

      onSelectImage(image.urls.regular);
      onClose();
    },
    [execTrackDownload, onClose, onSelectImage]
  );

  const {
    data: unsplashQuery,
    loading: isLoading,
    fetchMore,
  } = useSearchUnsplashImagesQuery({
    variables: {
      searchUnsplashImagesInput: {
        query,
        orderBy,
        orientation: orientation === "all" ? null : orientation,
        page: 1,
      },
    },
  });
  const nextPage = unsplashQuery?.searchUnsplashImages?.object?.nextPage || 1;

  const loadMoreImages = useCallback(
    async (e: UIEvent<HTMLDivElement>) => {
      const target = e.target as HTMLElement;
      const bottomOffset = target.scrollHeight - target.scrollTop - target.clientHeight;
      if (bottomOffset < 10 && nextPage) {
        await fetchMore({
          variables: {
            searchUnsplashImagesInput: {
              query,
              orderBy,
              orientation: orientation === "all" ? null : orientation,
              page: nextPage,
            },
          },
          updateQuery: (prev, { fetchMoreResult }): SearchUnsplashImagesQuery => {
            if (!fetchMoreResult) {
              return prev;
            }
            const prevItems = prev?.searchUnsplashImages?.object?.results || [];
            const nextItems = fetchMoreResult?.searchUnsplashImages?.object?.results || [];

            if (!prev.searchUnsplashImages.object) {
              return prev;
            }

            return {
              ...prev,
              searchUnsplashImages: {
                ...prev.searchUnsplashImages,
                object: {
                  ...prev.searchUnsplashImages.object,
                  ...fetchMoreResult?.searchUnsplashImages?.object,
                  results: [...prevItems, ...nextItems],
                },
              },
            };
          },
        });
      }
    },
    [fetchMore, nextPage, orientation, orderBy, query]
  );

  const total = unsplashQuery?.searchUnsplashImages?.object?.total || 0;
  const imagesLength = unsplashQuery?.searchUnsplashImages?.object?.results.length || 0;
  const leftImages = unsplashQuery?.searchUnsplashImages?.object?.results?.filter((_image, index) => index % 2 === 0);
  const rightImages = unsplashQuery?.searchUnsplashImages?.object?.results?.filter((_image, index) => index % 2 === 1);

  const UnsplashColumn = (images?: UnsplashImage[]) => {
    return (
      <Grid container spacing={1}>
        {images?.map((image) => {
          const maxWidth = 280;
          const ratio = image.width / image.height;
          return (
            <Grid item xs={12} key={image.id}>
              <Image
                alt={image.alt_description || "unsplash-image"}
                width={maxWidth}
                height={maxWidth / ratio}
                src={image.urls.small}
                className={classes.image}
                onClick={selectUnsplashImage(image)}
              />
              <Typography variant="body2" className={classes.imageLegend}>
                {image.user.name}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const UnsplashColumnLoader = () => {
    const images = new Array(10).fill(undefined);
    return (
      <Grid container spacing={2}>
        {images?.map((_image: undefined, index: number) => {
          const width = Math.random() * 1000;
          const height = Math.random() * 1000;
          const maxWidth = 280;
          const ratio = width / height;
          return (
            <Grid item xs={12} key={index}>
              <Skeleton variant="rectangular" width={maxWidth} height={maxWidth / ratio} />
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const keywords = [
    t("common", "animal"),
    t("common", "colorful"),
    t("common", "food"),
    t("common", "nature"),
    t("common", "environment"),
  ];
  const selectKeyWord = (keyword: string) => () => {
    setQuery(keyword);
    setSearch(keyword);
    setDisplayKeyWords(false);
  };

  return (
    <Dialog
      title={t("common", "selectImage")}
      open={isOpen}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      disableEnforceFocus
      hideIcon
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            value={search}
            onChange={handleChangeSearch}
            vibe="form"
            autoFocus
            endAdornment={
              <Icon vibe="tertiary">
                <Search />
              </Icon>
            }
          />
        </Grid>
        {displayKeyWords && (
          <Grid item xs={12}>
            <Grid container spacing={1}>
              {keywords.map((value, index) => {
                return (
                  <Grid item key={index}>
                    <Chip variant="outlined" label={value} onClick={selectKeyWord(value)} />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Select value={orientation} onChange={handleChangeOrientation}>
                {(Object.keys(UnsplashOrientation) as Array<keyof typeof UnsplashOrientation>).map((orientation) => {
                  return (
                    <MenuItem key={orientation} value={orientation}>
                      {t("common", orientation)}
                    </MenuItem>
                  );
                })}
                <MenuItem key="all" value="all">
                  {t("common", "allOrientations")}
                </MenuItem>
              </Select>
            </Grid>

            <Grid item>
              <Select value={orderBy} onChange={handleChangeOrder}>
                {(Object.keys(UnsplashOrderBy) as Array<keyof typeof UnsplashOrderBy>).map((orderBy) => {
                  return (
                    <MenuItem key={orderBy} value={orderBy}>
                      {t("common", orderBy)}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>

            <Grid item xs>
              <Grid container justifyContent="flex-end">
                <Typography variant="body2" className={classes.neutralVariant70}>
                  {t("common", "results", { total })}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {!isLoading && imagesLength === 0 && search && (
          <Grid item xs={12}>
            <Typography variant="body1" color="textSecondary">
              {t("common", "noResults")}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container className={classes.imagesContainer} spacing={1} onScroll={loadMoreImages}>
            <Grid item xs={6}>
              {!isLoading && UnsplashColumn(leftImages)}
              {isLoading && UnsplashColumnLoader()}
            </Grid>
            <Grid item xs={6}>
              {!isLoading && UnsplashColumn(rightImages)}
              {isLoading && UnsplashColumnLoader()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};
