import { useState, useRef, useEffect, ChangeEvent, useCallback, useImperativeHandle } from "react";

import { FieldValues, useWatch } from "react-hook-form";

import { AmplitudeEvents } from "../../constants/amplitude";
import { isRequired } from "../../helpers/validators";
import { useAmplitude } from "../../hooks/amplitude/useAmplitude";
import { CroppedFile } from "../Crop/Crop";

import { ImageUploaderHandle, ImageUploaderProps } from "./type";

export const getSizeInBite = (dataUrl: string) => {
  const head = "data:image/png;base64,";
  return Math.round(((dataUrl.length - head.length) * 3) / 4);
};

export const useImageUploader = <T extends FieldValues>(
  {
    control,
    imageUrl,
    name,
    onChange: handleChange,
    register,
    required,
    setDeleted,
    setValue,
    isEditing,
    formType,
    imageType,
    amplitudePayload,
  }: ImageUploaderProps<T>,
  ref?: React.Ref<ImageUploaderHandle>
) => {
  const [fileUrl, setFileUrl] = useState<string | undefined | null>(imageUrl ?? undefined);
  const [isCropOpen, setIsCropOpen] = useState(false);
  const [croppedFile, setCroppedFile] = useState<CroppedFile | undefined>(undefined);
  const [actualImageMetaData, setActualImageMetaData] = useState<
    { width: number; height: number; size: number; extension: string } | undefined
  >(undefined);

  const fillImageMetadata = useCallback((url: string) => {
    const isBlob = url.startsWith("data:");
    const extension = (isBlob ? url.split(";")[0].split("image/").pop() : url.split(".").pop()) ?? "none";
    const img = new Image();
    img.src = url;
    img.onload = function () {
      setActualImageMetaData({
        width: img.naturalWidth,
        height: img.naturalHeight,
        extension,
        size: getSizeInBite(url),
      });
    };
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      setImage: (url: string | null) => {
        setFileUrl(url || undefined);
      },
    }),
    []
  );

  const inputRef = useRef<HTMLInputElement | null>(null);
  const value = useWatch({ control, name });

  const handleCloseCrop = useCallback(() => {
    setIsCropOpen(false);
  }, []);

  const updateFileUrl = useCallback(
    (image: string | undefined | null) => {
      setFileUrl(image);
      if (name && setValue) {
        setValue(name, image as never, { shouldDirty: true });
      }
      if (image) {
        handleChange?.(image);
        fillImageMetadata(image);
      }
    },
    [fillImageMetadata, handleChange, name, setValue]
  );

  // Resets fileUrl only when imageUrl is null, handling banner type switches in Live Form Editor without affecting uploads.
  useEffect(() => {
    if (imageUrl === null) {
      setFileUrl(undefined);
    }
  }, [imageUrl]);

  const handleValidateCrop = useCallback(
    (image: string) => {
      updateFileUrl(image);
      setIsCropOpen(false);
    },
    [updateFileUrl]
  );

  useEffect(() => {
    if (value === undefined) {
      register(name, isRequired(Boolean(required) && !fileUrl, "common.fileRequired"));
    }
  }, [name, fileUrl, required, value, register]);

  const onFileChange = useCallback(
    async (file: File) => {
      const imageDataUrl = URL.createObjectURL(file);
      setCroppedFile({ url: imageDataUrl, type: file.type });
      fillImageMetadata(imageDataUrl);
      setIsCropOpen(true);
    },
    [fillImageMetadata]
  );

  const selectUnsplashImage = useCallback(
    (url: string) => {
      // TODO Add correct unsplash image type
      // Type is not normally sent with the unsplash image
      setCroppedFile({ url, type: "image/png" });
      fillImageMetadata(url);
      setIsCropOpen(true);
    },
    [fillImageMetadata]
  );

  const { logAmplitudeEvent } = useAmplitude();

  const handlePickImage = useCallback(() => {
    logAmplitudeEvent(AmplitudeEvents.FormImageUploadStarted, {
      is_new_form: !isEditing,
      form_type: formType,
      original_image_type: imageType,
      ...amplitudePayload,
    });
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  }, [amplitudePayload, formType, imageType, isEditing, logAmplitudeEvent]);

  const onInputFileChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      setIsCropOpen(true);
      const files = event && event.target ? event.target.files : null;
      if (files && files[0]) {
        onFileChange(files[0]);
      }
      event.target.value = ""; // reset the event allow to re-trigger the onChange, if we close the crop modal and re-choose the same file
    },
    [onFileChange]
  );

  const onDelete = useCallback(() => {
    setDeleted?.();
    if (inputRef && inputRef.current) {
      inputRef.current.value = "";
    }
    updateFileUrl(null);
    logAmplitudeEvent(AmplitudeEvents.FormImageDeleted, {
      is_new_form: !isEditing,
      form_type: formType,
      // disable this props until fixed
      original_image_size: actualImageMetaData?.size,
      original_image_width: actualImageMetaData?.width,
      original_image_height: actualImageMetaData?.height,
      original_image_type: imageType,
      original_image_extension: actualImageMetaData?.extension,
      ...amplitudePayload,
    });
  }, [
    setDeleted,
    updateFileUrl,
    logAmplitudeEvent,
    isEditing,
    formType,
    actualImageMetaData?.size,
    actualImageMetaData?.width,
    actualImageMetaData?.height,
    actualImageMetaData?.extension,
    imageType,
    amplitudePayload,
  ]);

  const [selectImageModalOpen, setSelectImageModalOpen] = useState(false);
  const openSelectImageModal = useCallback(() => {
    logAmplitudeEvent(AmplitudeEvents.CustomizationClickBannerUnsplash, amplitudePayload);
    setSelectImageModalOpen(true);
  }, [amplitudePayload, logAmplitudeEvent]);

  const closeSelectImageModal = useCallback(() => {
    setSelectImageModalOpen(false);
  }, []);

  return {
    fileUrl,
    setFileUrl,
    onDelete,
    onInputFileChange,
    handlePickImage,
    inputRef,
    isCropOpen,
    handleCloseCrop,
    handleValidateCrop,
    croppedFile,
    openSelectImageModal,
    closeSelectImageModal,
    selectImageModalOpen,
    selectUnsplashImage,
    actualImageMetaData,
    fillImageMetadata,
    onFileChange,
    setCroppedFile,
  };
};
