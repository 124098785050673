import { forwardRef, useCallback, useState } from "react";

import CardMedia from "@mui/material/CardMedia";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { PopoverOrigin } from "@mui/material/Popover";
import classnames from "classnames";

import { Button } from "../Button";
import { IconButton } from "../IconButton";
import { useMergeClasses } from "../useMergeClasses";

import { zeffyCardClasses, StyledCard } from "./styles";
import { CardProps } from "./types";

import { Typography } from "@/design-system/Typography/Typography";
import { MoreVertical } from "@/icons/outlined";

const anhorOrigin: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "left",
};

const transformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "left",
};

export const DEFAULT_CARD_VIBE = "brand";

export const Card = forwardRef<HTMLDivElement | null, CardProps>(
  (
    {
      active,
      avatar,
      children,
      classes: externalClasses,
      vibe = DEFAULT_CARD_VIBE,
      footerActions,
      fullWidth = false,
      headerActions,
      imageSrc,
      noHover,
      noBackgroundOnHover,
      disabled = false,
      onClick,
      subtitle,
      title,
      className,
      component = "div",
      ...rest
    },
    ref
  ) => {
    const [anchorToolMenu, setAnchorToolMenu] = useState<null | HTMLElement>(null);
    const onMenuClick = useCallback(
      (event: React.MouseEvent<HTMLElement>) => {
        setAnchorToolMenu(event.currentTarget);
      },
      [setAnchorToolMenu]
    );

    const onClose = useCallback(() => setAnchorToolMenu(null), []);
    const hasHeader = title || subtitle || avatar || (headerActions && headerActions.length > 0);

    const classes = useMergeClasses(zeffyCardClasses, externalClasses);

    return (
      <StyledCard
        {...rest}
        component={component}
        className={classnames(classes.root, className, {
          [classes.fullWidth]: fullWidth,
          [classes[vibe]]: !disabled,
          [classes[`${vibe}Hover`]]: !(noHover || imageSrc),
          [classes.active]: active && !disabled,
          [classes.noBackgroundOnHover]: noBackgroundOnHover,
          [classes.pointer]: Boolean(onClick),
        })}
        onClick={onClick}
        ref={ref}
      >
        {imageSrc && <CardMedia className={classes.image} image={imageSrc} />}
        {hasHeader && (
          <div className={classes.header}>
            <div className={classes.headerLeft}>
              {avatar && <div className={classes.avatar}>{avatar}</div>}
              {(title || subtitle) && (
                <div>
                  {title && (
                    <Typography variant="h6" className={classes.title}>
                      {title}
                    </Typography>
                  )}
                  {subtitle && (
                    <Typography variant="caption" className={classes.subtitle}>
                      {subtitle}
                    </Typography>
                  )}
                </div>
              )}
            </div>
            {headerActions && headerActions.length > 0 && (
              <IconButton vibe="neutral" onClick={onMenuClick} size="large">
                <MoreVertical />
              </IconButton>
            )}
          </div>
        )}
        {children && <div className={classes.content}>{children}</div>}
        {footerActions && footerActions.length > 0 && (
          <div className={classes.footer}>
            {footerActions?.map((buttonProps) => (
              <Button
                key={buttonProps.children?.toString()}
                vibe="brand"
                size="small"
                variant="text"
                {...buttonProps}
              />
            ))}
          </div>
        )}

        <Menu
          anchorOrigin={anhorOrigin}
          anchorEl={anchorToolMenu}
          transformOrigin={transformOrigin}
          open={Boolean(anchorToolMenu)}
          onClick={onClose}
          onClose={onClose}
        >
          {headerActions?.map(({ children, onClick }) => (
            <MenuItem key={children?.toString()} onClick={onClick}>
              {children}
            </MenuItem>
          ))}
        </Menu>
      </StyledCard>
    );
  }
);

Card.displayName = "Card";
