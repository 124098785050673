import { memo } from "react";

import MuiCard from "@mui/material/Card";
import { Theme, styled } from "@mui/material/styles";
import { CSSProperties } from "@mui/styles/withStyles";

import { InternalClasses } from "../helpers";

import { BaseCardClasses, CardVibe } from "./types";

const PREFIX = "ZeffyCard";

export const zeffyCardClasses: InternalClasses<typeof PREFIX, BaseCardClasses> = {
  root: `${PREFIX}-root`,
  fullWidth: `${PREFIX}-fullWidth`,
  active: `${PREFIX}-active`,
  brand: `${PREFIX}-brand`,
  positive: `${PREFIX}-positive`,
  brandHover: `${PREFIX}-brandHover`,
  positiveHover: `${PREFIX}-positiveHover`,
  noBackgroundOnHover: `${PREFIX}-noBackgroundOnHover`,
  image: `${PREFIX}-image`,
  header: `${PREFIX}-header`,
  headerLeft: `${PREFIX}-headerLeft`,
  avatar: `${PREFIX}-avatar`,
  title: `${PREFIX}-title`,
  subtitle: `${PREFIX}-subtitle`,
  content: `${PREFIX}-content`,
  footer: `${PREFIX}-footer`,
  pointer: `${PREFIX}-pointer`,
};

const getColorStyles = (theme: Theme, vibe: CardVibe): CSSProperties => {
  const activeSurfaceVibe = theme.palette.surface[vibe].quiet;
  return {
    [`&.${zeffyCardClasses.active}`]: {
      backgroundColor: activeSurfaceVibe,
      border: "1px solid",
      borderColor: vibe === "positive" ? theme.palette.border.neutral.moderate : theme.palette.border.neutral.quiet,
    },
  };
};

const getColorHoverStyles = (theme: Theme, vibe: CardVibe): CSSProperties => {
  const activeSurfaceVibe = theme.palette.surface[vibe].quiet;
  return {
    "&:hover": {
      backgroundColor: vibe === "brand" ? theme.palette.surface.neutral.supershy : theme.palette.surface[vibe].quiet,
    },
    "&:focus": {
      backgroundColor: activeSurfaceVibe,
    },
  };
};

export const StyledCard = memo(
  styled(MuiCard)(({ theme }: { theme: Theme }) => ({
    [`&.${zeffyCardClasses.root}`]: {
      border: `1px solid ${theme.palette.border.neutral.quiet}`,
      width: "100%",
      maxWidth: 360,
      padding: theme.spacing(0, 2),
      boxShadow: "none",
      transition: "background-color 0.2s ease-in-out",
    },

    [`&.${zeffyCardClasses.fullWidth}`]: {
      maxWidth: "unset",
    },

    [`&.${zeffyCardClasses.active}`]: {},
    [`&.${zeffyCardClasses.brand}`]: getColorStyles(theme, "brand"),
    [`&.${zeffyCardClasses.positive}`]: getColorStyles(theme, "positive"),
    [`&.${zeffyCardClasses.brandHover}`]: getColorHoverStyles(theme, "brand"),
    [`&.${zeffyCardClasses.positiveHover}`]: getColorHoverStyles(theme, "positive"),

    [`&.${zeffyCardClasses.noBackgroundOnHover}`]: {
      "&:hover": {
        backgroundColor: "unset",
        borderColor: theme.palette.border.neutral.moderate,
      },
    },

    [`& .${zeffyCardClasses.image}`]: {
      height: 240,
      padding: theme.spacing(1),
      width: "100%",
    },

    [`& .${zeffyCardClasses.header}`]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: theme.spacing(2, 0),
    },

    [`& .${zeffyCardClasses.headerLeft}`]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
    },

    [`& .${zeffyCardClasses.avatar}`]: {
      marginRight: theme.spacing(2),
    },

    [`& .${zeffyCardClasses.title}`]: {
      color: theme.palette.text.neutral.intense,
      fill: theme.palette.text.neutral.intense,
    },

    [`& .${zeffyCardClasses.subtitle}`]: {
      color: theme.palette.text.neutral.quiet,
    },

    [`& .${zeffyCardClasses.content}`]: {
      padding: theme.spacing(1, 0),
    },

    [`& .${zeffyCardClasses.footer}`]: {
      padding: theme.spacing(2, 0),
      "& button": {
        marginLeft: theme.spacing(1),
        "&:first-child": {
          marginLeft: 0,
        },
      },
    },

    [`&.${zeffyCardClasses.pointer}`]: {
      cursor: "pointer",
    },
  }))
) as typeof MuiCard;
