import { forwardRef, Ref, RefAttributes, useEffect, useRef, useState } from "react";

import Grid from "@mui/material/Grid";
import { Ratio } from "@simplyk/common";
import classNames from "classnames";
import { FieldValues } from "react-hook-form";

import { useTranslate } from "../../hooks/useTranslate";
import { useWidth } from "../../hooks/useWidth";
import { CropDialog } from "../Crop/CropDialog";
import { Button } from "../design-system/Button";
import { Typography } from "../design-system/Typography/Typography";
import { SelectImageDialog } from "../SelectImageDialog/SelectImageDialog";

import { classes, StyledCard } from "./styles";
import { ImageUploaderHandle, ImageUploaderProps, RatioSizes } from "./type";
import { useImageUploader } from "./useImageUploader";

export const ImageUploader = forwardRef(ImageUploaderInner) as unknown as <T extends FieldValues>(
  props: ImageUploaderProps<T> & RatioSizes & RefAttributes<ImageUploaderHandle>
) => React.ReactElement<ImageUploaderProps<T> & RatioSizes>;

export function ImageUploaderInner<T extends FieldValues>(
  { "data-test": dataTest, disabled = false, allowUnsplash, ...props }: ImageUploaderProps<T> & RatioSizes,
  ref: Ref<ImageUploaderHandle>
): React.ReactElement<ImageUploaderProps<T> & RatioSizes> {
  const { t } = useTranslate();
  const {
    fileUrl,
    onDelete,
    onInputFileChange,
    handlePickImage,
    inputRef,
    isCropOpen,
    handleCloseCrop,
    handleValidateCrop,
    croppedFile,
    openSelectImageModal,
    closeSelectImageModal,
    selectImageModalOpen,
    selectUnsplashImage,
    actualImageMetaData,
    fillImageMetadata,
    onFileChange,
    setCroppedFile,
  } = useImageUploader(props, ref);

  const currentDisabled = disabled;
  const ratioValue = props.ratio === Ratio.Square ? 1 : 16 / 9;

  const [imageUploaderWidth, setImageUploaderWidth] = useState(0);
  const imageUploaderHeight = imageUploaderWidth / ratioValue;
  const imageUploaderRef = useRef(null);
  const { getWidths } = useWidth([imageUploaderRef]);
  useEffect(() => {
    const [columnWidth] = getWidths();
    setImageUploaderWidth(columnWidth);
  }, [getWidths]);

  return (
    <>
      {isCropOpen && (
        <CropDialog
          isOpen={isCropOpen}
          onCrop={handleValidateCrop}
          onClose={handleCloseCrop}
          croppedFile={croppedFile}
          ratio={ratioValue}
          maxDimensions={props.maxDimensions}
          isEditing={props.isEditing}
          formType={props.formType}
          imageType={props.imageType}
          actualImageMetaData={actualImageMetaData}
          fillImageMetadata={fillImageMetadata}
          onFileChange={onFileChange}
          handlePickImage={handlePickImage}
          setCroppedFile={setCroppedFile}
          amplitudePayload={props.amplitudePayload}
        />
      )}
      {selectImageModalOpen && (
        <SelectImageDialog
          isOpen={selectImageModalOpen}
          onClose={closeSelectImageModal}
          onSelectImage={selectUnsplashImage}
        />
      )}
      <StyledCard
        variant="outlined"
        fullWidth
        noHover
        className={props.className}
        classes={{
          root: classNames(classes.uploadCard, classes.noPadding),
          content: classes.noPadding,
        }}
        data-test={dataTest}
        ref={imageUploaderRef}
        style={{ height: imageUploaderHeight }}
      >
        <input
          name={props.name}
          accept="image/*"
          className={classes.invisibleInput}
          id={props.name}
          type="file"
          onChange={onInputFileChange}
          ref={inputRef}
          disabled={currentDisabled}
        />
        <Grid
          container
          className={classNames({ [classes.buttonDisabled]: currentDisabled })}
          justifyContent="center"
          alignItems="center"
          style={{
            backgroundImage: `url(${fileUrl})`,
            backgroundSize: "cover",
            height: imageUploaderHeight,
          }}
        >
          {!allowUnsplash && (
            <>
              {!fileUrl && (
                <Grid
                  container
                  justifyContent="center"
                  className={classes.containerWithoutImage}
                  spacing={1}
                  onClick={handlePickImage}
                >
                  <Grid item>
                    <Button variant="tonal" vibe="neutral" data-test={`${dataTest}-upload-button`}>
                      {props.importLabel}
                    </Button>
                  </Grid>
                </Grid>
              )}
            </>
          )}
          {allowUnsplash && (
            <Grid container justifyContent="center" className={classes.containerWithoutImage} spacing={1}>
              <Grid item>
                <Grid container spacing={2}>
                  {!fileUrl && (
                    <Grid item container alignItems="center" spacing={1} flexDirection="column" flexWrap="wrap">
                      <Grid item alignSelf="stretch">
                        <Button
                          variant="tonal"
                          vibe="neutral"
                          onClick={handlePickImage}
                          fullWidth
                          data-test={`${dataTest}-upload-button`}
                        >
                          {props.importLabel}
                        </Button>
                      </Grid>
                      {(process.env.NODE_ENV !== "production" || props.handleFillWithPlaceholder) && (
                        <>
                          <Grid item>
                            <Typography variant="body2" vibe="text-neutral-quiet">
                              {t("common", "or")}
                            </Typography>
                          </Grid>
                          <Grid item alignSelf="stretch">
                            <Button
                              variant="outlined"
                              vibe="neutral"
                              onClick={props.handleFillWithPlaceholder ?? openSelectImageModal}
                              fullWidth
                            >
                              {props.handleFillWithPlaceholder
                                ? t("dashboard", "fillWithPlaceholderBanner")
                                : t("common", "selectImage")}
                            </Button>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
          {fileUrl && (
            <Grid item container height="100%" alignItems="center" justifyContent="center" spacing={2} padding={1}>
              <Grid item>
                <Button
                  variant="outlined"
                  vibe="neutral"
                  onClick={handlePickImage}
                  style={{ backgroundColor: "white" }}
                  data-test={`${dataTest}-replace-button`}
                >
                  {t("common", "replaceImage")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  vibe="neutral"
                  onClick={onDelete}
                  style={{ backgroundColor: "white" }}
                  data-test={`${dataTest}-delete-button`}
                >
                  {t("common", "deleteImage")}
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </StyledCard>
    </>
  );
}
