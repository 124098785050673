import { PixelCrop } from "react-image-crop";

import { ImageDimensions } from "@/types/image";

const TO_RADIANS = Math.PI / 180;

function hasTransparentBackground(context: CanvasRenderingContext2D, canvas: HTMLCanvasElement): boolean {
  const data = context.getImageData(0, 0, canvas.width, canvas.height).data;
  for (let i = 3, n = data.length; i < n; i += 4) {
    // If the alpha value is less than 255, the pixel is transparent
    if (data[i] < 255) {
      return true;
    }
  }
  return false;
}

const getOutputImageMimeType = (
  originalMimeType: string,
  context: CanvasRenderingContext2D,
  canvas: HTMLCanvasElement
): string => {
  // If the original image is a PNG and has no transparent background, convert it to JPEG
  if (originalMimeType === "image/png" && !hasTransparentBackground(context, canvas)) {
    return "image/jpeg";
  }

  return originalMimeType;
};

const compressImage = ({
  mimeType,
  canvas,
  maxDimensions,
  canvasToEnhance,
}: {
  mimeType: string;
  canvas: HTMLCanvasElement;
  maxDimensions: ImageDimensions | undefined;
  canvasToEnhance: HTMLCanvasElement;
}): {
  image: string;
  width: number;
  height: number;
  isCompressed: boolean;
  imageToEnhance: { image: string; dimensions: ImageDimensions };
} => {
  // Only resize the image if the width or height exceeds the maximum
  if (!maxDimensions || (canvas.width <= maxDimensions.width && canvas.height <= maxDimensions.height)) {
    // Retrieve the compressed image data
    // We're decreasing the quality to 0.8 to reduce the file size
    return {
      image: canvas.toDataURL(mimeType, 0.8),
      width: canvas.width,
      height: canvas.height,
      isCompressed: false,
      imageToEnhance: {
        image: canvasToEnhance.toDataURL(mimeType, 0.8),
        dimensions: { width: canvasToEnhance.width, height: canvasToEnhance.height },
      },
    };
  }

  // Get the new dimensions
  let newWidth = canvas.width;
  let newHeight = canvas.height;
  const aspectRatio = canvas.width / canvas.height;

  if (newWidth > maxDimensions.width) {
    newWidth = maxDimensions.width;
    newHeight = maxDimensions.width / aspectRatio;
  } else if (newHeight > maxDimensions.height) {
    newWidth = maxDimensions.height * aspectRatio;
    newHeight = maxDimensions.height;
  }

  // Create a new canvas element with the desired dimensions
  const compressedCanvas = document.createElement("canvas");
  compressedCanvas.width = newWidth;
  compressedCanvas.height = newHeight;

  const compressedCtx = compressedCanvas.getContext("2d");

  // Draw the original image onto the new canvas, resizing it
  compressedCtx?.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, newWidth, newHeight);

  // Retrieve the compressed image data
  // We're decreasing the quality to 0.8 to reduce the file size
  return {
    image: compressedCanvas.toDataURL(mimeType, 0.8),
    width: newWidth,
    height: newHeight,
    isCompressed: true,
    imageToEnhance: {
      image: canvasToEnhance.toDataURL(mimeType, 0.8),
      dimensions: { width: canvasToEnhance.width, height: canvasToEnhance.height },
    },
  };
};

const drawImage = (ctx: CanvasRenderingContext2D, image: HTMLImageElement) => {
  ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, 0, 0, image.naturalWidth, image.naturalHeight);
};

const limitDimensions = async ({
  targetWidth,
  targetHeight,
}: {
  targetWidth: number;
  targetHeight: number;
}): Promise<{
  safeWidth: number;
  safeHeight: number;
  scale: number;
}> => {
  // Get the maximum supported canvas size for the browser
  const canvasSize = await import("canvas-size");
  const maxCanvasSize = await canvasSize.default.maxArea({
    usePromise: true,
  });

  // Calculate scaling factor for both width and height constraints
  const widthScale = maxCanvasSize.width / targetWidth;
  const heightScale = maxCanvasSize.height / targetHeight;

  // Scale down only if needed
  const scale = Math.min(1, widthScale, heightScale);

  // Apply scaling factor
  return { safeWidth: Math.floor(targetWidth * scale), safeHeight: Math.floor(targetHeight * scale), scale };
};

const getSafeDimensions = async ({
  image,
  crop,
  scaleX,
  scaleY,
}: {
  image: HTMLImageElement;
  crop: PixelCrop;
  scaleX: number;
  scaleY: number;
}): Promise<{
  safeCanvasWidth: number;
  safeCanvasHeight: number;
  safeCroppedCanvasWidth: number;
  safeCroppedCanvasHeight: number;
  cropScale: number;
}> => {
  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.

  // Calculate the safe image dimensions
  const safeImageDimensions = await limitDimensions({
    targetWidth: image.naturalWidth,
    targetHeight: image.naturalHeight,
  });

  // Calculate target canvas dimensions based on crop and scaling
  const safeCroppedDimensions = await limitDimensions({
    targetWidth: Math.floor(crop.width * scaleX * window.devicePixelRatio),
    targetHeight: Math.floor(crop.height * scaleY * window.devicePixelRatio),
  });

  return {
    safeCanvasWidth: safeImageDimensions.safeWidth,
    safeCanvasHeight: safeImageDimensions.safeHeight,
    safeCroppedCanvasWidth: safeCroppedDimensions.safeWidth,
    safeCroppedCanvasHeight: safeCroppedDimensions.safeHeight,
    cropScale: safeCroppedDimensions.scale,
  };
};

export async function canvasPreview(
  originalMimeType: string,
  image: HTMLImageElement,
  canvas: HTMLCanvasElement,
  crop: PixelCrop,
  maxDimensions?: ImageDimensions,
  rotate = 0
) {
  const ctx = canvas.getContext("2d");

  // Create a new canvas to use for enhancement without any crop
  const canvasToEnhance = document.createElement("canvas");
  const ctxToEnhance = canvasToEnhance.getContext("2d");

  if (!ctx || !ctxToEnhance) {
    throw new Error("No 2d context");
  }

  // Calculate the scale factors for the image
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;

  const { safeCanvasWidth, safeCanvasHeight, safeCroppedCanvasWidth, safeCroppedCanvasHeight, cropScale } =
    await getSafeDimensions({ image, crop, scaleX, scaleY });

  // Set canvas dimensions within constraints
  canvas.width = safeCroppedCanvasWidth;
  canvas.height = safeCroppedCanvasHeight;

  // Scale the canvas to the appropriate size
  ctx.scale(window.devicePixelRatio * cropScale, window.devicePixelRatio * cropScale);
  ctx.imageSmoothingQuality = "high";

  // Calculate the cropped coordinates
  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const rotateRads = rotate * TO_RADIANS;
  const croppedCenterX = image.naturalWidth / 2;
  const croppedCenterY = image.naturalHeight / 2;

  ctx.save();
  ctx.translate(-cropX, -cropY); // Move the origin to the top-left corner of the crop
  ctx.translate(croppedCenterX, croppedCenterY); // Move the origin to the center of the image
  ctx.rotate(rotateRads); // Rotate the canvas
  ctx.translate(-croppedCenterX, -croppedCenterY); // Move the origin back to the top-left corner of the crop
  drawImage(ctx, image);

  ctx.restore();

  const outputMimeType = getOutputImageMimeType(originalMimeType, ctx, canvas);

  // Apply the same transformation to the canvasToEnhance, but without crop
  // Set dimensions of canvasToEnhance within maximum size constraints
  canvasToEnhance.width = safeCanvasWidth;
  canvasToEnhance.height = safeCanvasHeight;
  ctxToEnhance.imageSmoothingQuality = "high";

  const centerX = safeCanvasWidth / 2;
  const centerY = safeCanvasHeight / 2;

  ctxToEnhance.save();
  ctxToEnhance.translate(centerX, centerY);
  ctxToEnhance.rotate(rotateRads);
  ctxToEnhance.translate(-centerX, -centerY);
  drawImage(ctxToEnhance, image);
  ctxToEnhance.restore();

  return compressImage({ mimeType: outputMimeType, canvas, maxDimensions, canvasToEnhance });
}

export async function getImageDimensions(blob: Blob) {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      resolve({
        width: img.width,
        height: img.height,
        naturalWidth: img.naturalWidth,
        naturalHeight: img.naturalHeight,
      });
    };
    img.src = URL.createObjectURL(blob);
  }) as Promise<{ width: number; height: number; naturalWidth: number; naturalHeight: number }>;
}
