import { useCallback } from "react";

import { Cloudinary } from "@cloudinary/url-gen";
import { upscale } from "@cloudinary/url-gen/actions/effect";
import { SHA1, enc } from "crypto-js";

export const useCloudinary = () => {
  const downloadImageFromUrl = async (url: string) => {
    return fetch(url)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        return blob;
      });
  };

  const uploadImage = useCallback(async (file: string): Promise<string> => {
    const cloudName = process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME;
    const url = `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`;
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "b7n2rpor");
    return await fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())

      .then((data: { secure_url: string }) => {
        return data.secure_url;
      });
  }, []);

  const extractPublicId = (url: string) => {
    // eslint-disable-next-line no-useless-escape
    const matches = url.match(/\/upload\/(?:v\d+\/)?([^\.]+)/);
    return matches ? matches[1] : null;
  };

  const deleteImageFromUrl = useCallback(async (link: string) => {
    const cloudName = process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME;
    const publicId = extractPublicId(link);
    if (!publicId) {
      return;
    }
    const url = `https://api.cloudinary.com/v1_1/${cloudName}/image/destroy`;
    const timestamp = Math.floor(Date.now() / 1000);
    const apiKey = process.env.NEXT_PUBLIC_CLOUDINARY_API_KEY as string;
    const apiSecret = process.env.NEXT_PUBLIC_CLOUDINARY_API_SECRET;

    const stringToSign = `public_id=${publicId}&timestamp=${timestamp}${apiSecret}`;
    const sha1 = SHA1(stringToSign);
    const signature = sha1.toString(enc.Hex);

    const formData = new FormData();

    formData.append("public_id", publicId);
    formData.append("timestamp", timestamp.toString());
    formData.append("api_key", apiKey);
    formData.append("signature", signature);

    fetch(url, {
      method: "POST",
      body: formData,
    }).then((response) => response.json());
  }, []);

  const getUpScaledImage = useCallback(
    async (src: string) => {
      const cloudinary = new Cloudinary({ cloud: { cloudName: "hxn9dbuhd" } });

      const uploadUrl = await uploadImage(src);
      const publicId = extractPublicId(uploadUrl);
      if (!publicId) {
        return;
      }
      const image = cloudinary.image(publicId); // publicId
      image.effect(upscale());
      const urlResized = image.toURL();

      const blob = await downloadImageFromUrl(urlResized);

      await deleteImageFromUrl(uploadUrl);
      return blob;
    },
    [deleteImageFromUrl, uploadImage]
  );

  return { getUpScaledImage };
};
