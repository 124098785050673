import { Dispatch, RefObject, SetStateAction, useCallback, useState } from "react";

import Grid from "@mui/material/Grid";
import dynamic from "next/dynamic";
import ReactCrop, { Crop as CropState, PixelCrop, centerCrop, makeAspectCrop } from "react-image-crop";

import { AmplitudeEvents } from "../../constants/amplitude";

import enhancingImage from "./animations/enhancing_image_eng.json";
import { useStyles } from "./useStyles";

const Lottie = dynamic(() => import("react-lottie"), { ssr: false });

const centerAspectCrop = (mediaWidth: number, mediaHeight: number, aspect: number) => {
  return centerCrop(makeAspectCrop({ unit: "%", width: 99 }, aspect, mediaWidth, mediaHeight), mediaWidth, mediaHeight);
};

const MIN_WIDTH = 10;
const MIN_HEIGHT = 10;

export interface CroppedFile {
  url: string;
  type: string;
}

interface CropProps {
  croppedFile?: CroppedFile;
  ratio: number;
  rotate: number;
  handleAmplitudeEvent: (event: AmplitudeEvents) => void;
  completedCrop: PixelCrop | undefined;
  setCompletedCrop: Dispatch<SetStateAction<PixelCrop | undefined>>;
  imgRef: RefObject<HTMLImageElement | null>;
  previewCanvasRef: RefObject<HTMLCanvasElement | null>;
  animationMinimumDelay: boolean;
  imageIsEnhancing: boolean;
  setImageCroppedByUser: Dispatch<SetStateAction<boolean>>;
}

export const Crop = ({
  croppedFile,
  ratio,
  rotate,
  handleAmplitudeEvent,
  completedCrop,
  setCompletedCrop,
  previewCanvasRef,
  imgRef,
  animationMinimumDelay,
  imageIsEnhancing,
  setImageCroppedByUser,
}: CropProps) => {
  const classes = useStyles();

  const [crop, setCrop] = useState<CropState>(); // control the cropper
  const handleCrop = useCallback((crop: CropState) => {
    setCrop(crop);
  }, []);
  const handleCompleteCrop = useCallback(
    (crop: PixelCrop) => {
      setCompletedCrop(crop);
    },
    [setCompletedCrop]
  );

  const onImageLoad = useCallback(
    async (e: React.SyntheticEvent<HTMLImageElement>) => {
      if (ratio) {
        const { width, height } = e.currentTarget;
        setCrop(centerAspectCrop(width, height, ratio));
      }
    },
    [ratio]
  );

  const handleClick = useCallback(() => {
    setImageCroppedByUser(true);
    handleAmplitudeEvent(AmplitudeEvents.FormImageCropped);
  }, [handleAmplitudeEvent, setImageCroppedByUser]);

  return (
    <>
      {croppedFile && (
        <Grid item xs={12} className={classes.centered} onClick={handleClick}>
          <ReactCrop
            crop={crop}
            onChange={handleCrop}
            aspect={ratio}
            onComplete={handleCompleteCrop}
            minWidth={MIN_WIDTH}
            minHeight={MIN_HEIGHT}
            style={{ height: "100%" }}
            className={classes.reactCrop}
          >
            <img
              alt="Cropped-image"
              src={croppedFile.url}
              ref={imgRef}
              onLoad={onImageLoad}
              style={{ transform: `rotate(${rotate}deg)` }}
              crossOrigin="anonymous"
              height="100%"
              width={undefined}
            />
          </ReactCrop>
          {(imageIsEnhancing || animationMinimumDelay) && (
            <div className={classes.enhancingImage}>
              <Lottie
                options={{
                  animationData: enhancingImage,
                  autoplay: true,
                }}
                isClickToPauseDisabled={true}
                style={{ width: "150px", height: "150px" }}
              />
            </div>
          )}
        </Grid>
      )}
      {completedCrop && (
        <canvas
          id="crop"
          ref={previewCanvasRef}
          style={{
            border: "1px solid black",
            objectFit: "contain",
            width: completedCrop.width,
            height: completedCrop.height,
            maskRepeat: "no-repeat",
            display: "none",
          }}
        />
      )}
    </>
  );
};
