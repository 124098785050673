import { ImageDimensions } from "@/types/image";

type ImageTypes = "banner" | "logo" | "shopRateItem" | "signature";

export const MAX_IMAGE_SIZES: Record<ImageTypes, ImageDimensions> = {
  banner: { width: 2048, height: 1152 },
  logo: { width: 800, height: 800 },
  shopRateItem: { width: 800, height: 800 },
  signature: { width: 1280, height: 720 },
};
