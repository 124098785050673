import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  imagesContainer: {
    height: 650,
    overflowY: "auto",
  },
  imageBlock: {
    display: "block",
  },
  image: {
    cursor: "pointer",
  },
  imageLegend: {
    color: theme.palette.neutralVariant[70],
    textDecoration: "underline",
  },
  neutralVariant70: {
    color: theme.palette.neutralVariant[70],
  },
}));
