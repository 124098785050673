import { useCallback } from "react";

export const useWidth = (divRefs: React.RefObject<HTMLDivElement | null>[]) => {
  const getWidths = useCallback(
    () =>
      divRefs.map((divRef) => {
        if (divRef.current) {
          return divRef.current.clientWidth;
        }
        return 0;
      }),
    [divRefs]
  );

  return { getWidths };
};
