import { memo } from "react";

import { styled, Theme } from "@mui/material/styles";

import { Card } from "@/design-system/Card/Card";

const PREFIX = "ImageUploader";

export const classes = {
  containerWithoutImage: `${PREFIX}-containerWithoutImage`,
  textAlignCenter: `${PREFIX}-textAlignCenter`,
  iconContainer: `${PREFIX}-iconContainer`,
  imageSkeleton: `${PREFIX}-imageSkeleton`,
  icon: `${PREFIX}-icon`,
  invisibleInput: `${PREFIX}-invisibleInput`,
  deleteButton: `${PREFIX}-deleteButton`,
  uploadCard: `${PREFIX}-uploadCard`,
  noPadding: `${PREFIX}-noPadding`,
  errorMessage: `${PREFIX}-errorMessage`,
  button: `${PREFIX}-button`,
  buttonDisabled: `${PREFIX}-buttonDisabled`,
};

export const StyledCard = memo(
  styled(Card)(({ theme }: { theme: Theme }) => ({
    [`& .${classes.containerWithoutImage}`]: {
      padding: theme.spacing(1),
      position: "relative",
    },

    [`& .${classes.textAlignCenter}`]: {
      textAlign: "center",
    },

    [`& .${classes.iconContainer}`]: {
      height: theme.icon.size.default.medium,
      width: theme.icon.size.default.medium,
      boxSizing: "content-box",
      display: "flex",
      justifyContent: "center",
    },

    [`& .${classes.imageSkeleton}`]: {
      height: 80,
      width: 80,
      boxSizing: "content-box",
    },

    [`& .${classes.icon}`]: {},

    [`& .${classes.invisibleInput}`]: {
      display: "none",
    },

    [`& .${classes.deleteButton}`]: {
      position: "absolute",
      top: 0,
      right: 0,
      color: theme.palette.error.main,
      zIndex: 11, // < zIndexHeader = 12
    },

    [`&.${classes.uploadCard}`]: {
      position: "relative",
      height: "100%",
      backgroundColor: theme.palette.surface.neutral.supershy,
      borderColor: theme.palette.border.neutral.moderate,
      borderStyle: "dashed",

      "&:hover": {
        backgroundColor: theme.palette.surface.neutral.supershy,
      },
    },

    [`&.${classes.noPadding}, .${classes.noPadding}`]: {
      padding: 0,
    },

    [`& .${classes.errorMessage}`]: {
      marginLeft: 14,
    },

    [`& .${classes.button}`]: {
      color: theme.palette.neutralVariant[20],
      opacity: 1,
      cursor: "pointer",

      "&:hover": {
        color: theme.palette.primary.main,
        [`& .${classes.icon}`]: {
          fill: theme.palette.primary.main,
        },
      },
    },

    [`& .${classes.buttonDisabled}`]: {
      cursor: "disabled",
      color: theme.palette.neutralVariant[20],
    },
  }))
);
