import * as Types from '../../gql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SearchUnsplashImagesQueryVariables = Types.Exact<{
  searchUnsplashImagesInput: Types.SearchUnsplashImagesInput;
}>;


export type SearchUnsplashImagesQuery = { __typename?: 'Query', searchUnsplashImages: { __typename?: 'SearchUnsplashImagesResponseObject', error?: { __typename?: 'SearchUnsplashImagesResponseObjectErrorObject', code?: string | null, message: string } | null, object?: { __typename?: 'SearchUnsplashImagesObject', total: number, nextPage: number, results: Array<{ __typename?: 'UnsplashImageObject', id: string, width: number, height: number, description?: string | null, alt_description?: string | null, urls: { __typename?: 'UnsplashUrls', small: string, regular: string, full: string }, user: { __typename?: 'UnsplashUser', id: string, name: string }, links: { __typename?: 'UnsplashLinks', download_location: string } }> } | null } };

export type TrackDownloadMutationVariables = Types.Exact<{
  location: Types.Scalars['String'];
}>;


export type TrackDownloadMutation = { __typename?: 'Mutation', trackDownload?: boolean | null };


export const SearchUnsplashImagesDocument = gql`
    query searchUnsplashImages($searchUnsplashImagesInput: SearchUnsplashImagesInput!) {
  searchUnsplashImages(searchUnsplashImagesInput: $searchUnsplashImagesInput) {
    error {
      code
      message
    }
    object {
      total
      nextPage
      results {
        id
        width
        height
        description
        alt_description
        urls {
          small
          regular
          full
        }
        user {
          id
          name
        }
        links {
          download_location
        }
      }
    }
  }
}
    `;

/**
 * __useSearchUnsplashImagesQuery__
 *
 * To run a query within a React component, call `useSearchUnsplashImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUnsplashImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUnsplashImagesQuery({
 *   variables: {
 *      searchUnsplashImagesInput: // value for 'searchUnsplashImagesInput'
 *   },
 * });
 */
export function useSearchUnsplashImagesQuery(baseOptions: Apollo.QueryHookOptions<SearchUnsplashImagesQuery, SearchUnsplashImagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchUnsplashImagesQuery, SearchUnsplashImagesQueryVariables>(SearchUnsplashImagesDocument, options);
      }
export function useSearchUnsplashImagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchUnsplashImagesQuery, SearchUnsplashImagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchUnsplashImagesQuery, SearchUnsplashImagesQueryVariables>(SearchUnsplashImagesDocument, options);
        }
export type SearchUnsplashImagesQueryHookResult = ReturnType<typeof useSearchUnsplashImagesQuery>;
export type SearchUnsplashImagesLazyQueryHookResult = ReturnType<typeof useSearchUnsplashImagesLazyQuery>;
export type SearchUnsplashImagesQueryResult = Apollo.QueryResult<SearchUnsplashImagesQuery, SearchUnsplashImagesQueryVariables>;
export const TrackDownloadDocument = gql`
    mutation trackDownload($location: String!) {
  trackDownload(location: $location)
}
    `;
export type TrackDownloadMutationFn = Apollo.MutationFunction<TrackDownloadMutation, TrackDownloadMutationVariables>;

/**
 * __useTrackDownloadMutation__
 *
 * To run a mutation, you first call `useTrackDownloadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackDownloadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackDownloadMutation, { data, loading, error }] = useTrackDownloadMutation({
 *   variables: {
 *      location: // value for 'location'
 *   },
 * });
 */
export function useTrackDownloadMutation(baseOptions?: Apollo.MutationHookOptions<TrackDownloadMutation, TrackDownloadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TrackDownloadMutation, TrackDownloadMutationVariables>(TrackDownloadDocument, options);
      }
export type TrackDownloadMutationHookResult = ReturnType<typeof useTrackDownloadMutation>;
export type TrackDownloadMutationResult = Apollo.MutationResult<TrackDownloadMutation>;
export type TrackDownloadMutationOptions = Apollo.BaseMutationOptions<TrackDownloadMutation, TrackDownloadMutationVariables>;