import { FormCategory, Ratio } from "@simplyk/common";
import { Control, FieldValues, Path, UseFormRegister, UseFormSetValue } from "react-hook-form";

import { ImageDimensions } from "@/types/image";

type Sizes = [100, 100] | [188, 188] | [400, 400] | [800, 800] | [140, 140] | [1024, 576] | [2048, 1152];

export enum ImageType {
  Logo = "logo",
  Banner = "banner",
  Signature = "signature",
  OrganizationLogo = "organizationLogo",
  ItemVisual = "itemVisual",
  TicketBanner = "ticketBanner",
  ProfilePhoto = "profilePhoto",
}

export type RatioSizes =
  | {
      ratio: Ratio.Square;
      sizes: Sizes & ([100, 100] | [188, 188] | [400, 400] | [140, 140] | [800, 800]);
    }
  | {
      ratio: Ratio.Landscape;
      sizes: Sizes & ([1024, 576] | [2048, 1152]);
    };

export interface ImageUploaderHandle {
  setImage: (url: string | null) => void;
}

export interface ImageUploaderProps<T extends FieldValues> {
  className?: string;
  control: Control<T>;
  // Url of the image to display as a preview
  imageUrl?: string | null;
  disabled?: boolean;
  importLabel: string;
  isRequired?: boolean;
  name: Path<T>;
  onChange?: (imageDataUrl: string) => void;
  register: UseFormRegister<T>;
  required?: boolean;
  setDeleted?: () => void;
  setValue: UseFormSetValue<T>;
  "data-test"?: string;
  allowUnsplash?: boolean;
  maxDimensions: ImageDimensions | undefined;
  formType?: FormCategory;
  isEditing?: boolean;
  imageType: ImageType;
  handleFillWithPlaceholder?: () => Promise<void>;
  amplitudePayload?: Record<string, unknown>;
}
